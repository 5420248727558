<template>
    <div>
        <router-link :to="{ name: 'dashboard_profile' }" class="dropdown-item">
            <font-awesome-icon icon="user-circle"/>
            <span>Profil</span>
        </router-link>
        <router-link :to="{ name: 'about' }" class="dropdown-item">
            <font-awesome-icon :icon="['far', 'paper-plane']"/>
            <span>Contact</span>
        </router-link>
        <router-link :to="{ name: 'logout' }" class="dropdown-item">
            <i class="ni ni-button-power"></i>
            <span>Déconnexion</span>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "DashboardNavbarLinks"
    }
</script>
